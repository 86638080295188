import { createContext, ReactNode, useReducer } from "react";
import Page from "../model/Page";
import ChoctopusSettings from "../model/ChoctopusSettings";
import Settings from "../model/Settings";
import Menu from "../model/Menu";
import { Product } from "../model/Product";
import { Brand } from "../model/Brand";
import { Logo } from "../model/Logo";

type ContentContextType = {
  pages: [];
  menu: Menu;
  setting: ChoctopusSettings;
  products: Product[];
  brands: Brand[];
  setProducts: (products: Product[]) => void;
  setBrands: (brands: Brand[]) => void;
  setPages: (pages: Page[]) => void;
  setMenu: (menu: Menu) => void;
  setSettings: (settings: Settings) => void;
};

type ActionType = {
  type: string;
  value?: any;
};

const SET_PAGES = "setPages";
const SET_PRODUCTS = "setProducts";
const SET_BRANDS = "setBrands";
const SET_MENU = "setMenu";
const SET_SETTINGS = "setSettings";

const INITIAL_CONTENT_STATE: ContentContextType = {
  pages: [],
  menu: new Menu([]),
  setting: {} as ChoctopusSettings,
  products: [] as Product[],
  brands: [] as Brand[],
  setProducts: (products: Product[]) => {},
  setBrands: (brands: Brand[]) => {},
  setPages: (pages: Page[]) => {},
  setMenu: (menu: Menu) => {},
  setSettings: (settings: Settings) => {},
};

const contentReducer = (state: ContentContextType, action: ActionType): ContentContextType => {
  switch (action.type) {
    case SET_PAGES: {
      return {
        ...state,
        pages: action.value,
      };
    }
    case SET_MENU: {
      return {
        ...state,
        menu: action.value,
      };
    }
    case SET_SETTINGS: {
      return {
        ...state,
        setting: action.value.settings,
      };
    }
    case SET_PRODUCTS: {
      return {
        ...state,
        products: action.value,
      };
    }
    case SET_BRANDS: {
      return {
        ...state,
        brands: action.value,
      };
    }
    default:
      return state;
  }
};

export const ContentContextProvider = ({ children }: { children: ReactNode }) => {
  const [contentState, dispatch] = useReducer(contentReducer, INITIAL_CONTENT_STATE, undefined);

  const setPages = (pages: Page[]) => {
    dispatch({ type: SET_PAGES, value: pages });
  };
  const setMenu = (menu: Menu) => {
    dispatch({ type: SET_MENU, value: menu });
  };
  const setSettings = (settings: Settings) => {
    dispatch({ type: SET_SETTINGS, value: settings });
  };
  const setProducts = (products: Product[]) => {
    dispatch({ type: SET_PRODUCTS, value: products });
  };
  const setBrands = (brands: Brand[]) => {
    dispatch({ type: SET_BRANDS, value: brands });
  };
  const contextValue: ContentContextType = {
    ...contentState,
    setProducts: setProducts,
    setBrands: setBrands,
    setPages: setPages,
    setMenu: setMenu,
    setSettings: setSettings,
  };
  return <ContentContext.Provider value={contextValue}>{children}</ContentContext.Provider>;
};

const ContentContext = createContext<ContentContextType>({
  ...INITIAL_CONTENT_STATE,
});

export default ContentContext;
